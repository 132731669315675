const realityjam = `${process.env.REACT_APP_PUBLIC_MARKETPLACE_DASHBOARD}`;
const marketplace = `${process.env.REACT_APP_PUBLIC_MARKETPLACE_DASHBOARD}/marketplace`;
const userDashboard = `${process.env.REACT_APP_PUBLIC_USER_DASHBOARD}/vrjam`;
const aijamDashboard = `${process.env.REACT_APP_PUBLIC_USER_DASHBOARD}/aijam`;

export const paths = {
  vrjam: {
    home: 'https://vrjam.com/',
    press: 'https://vrjam.com/press/',
    publicRealm: 'https://vrjam.com/public-realm/',
    roadmap: 'https://vrjam.com/roadmap/',
    whitepaper: 'https://bit.ly/VRJAMwhitepaper',
    dimension: 'https://www.5thdimension.gg',
  },

  realityjam: {
    home: `${ realityjam }/`,
    createAvatar: `${realityjam}/create-avatar`,
    login: `${realityjam}/login`,
    signUp: `${realityjam}/signup`,
    resetPass: `${realityjam}/reset-password`,
    verifyUser: `${realityjam}/verification`,
    forgotPassword: `${realityjam}/forgot-password`,
    faq: `${realityjam}/faq`,
    deusx: `${realityjam}/deusx`,
    account: `${realityjam}/account`,
    settings: `${realityjam}/settings`,
  },

  marketplace: {
    home: `${marketplace}/`,
    venues: `${marketplace}/venues`,
    venueDetails: `${marketplace}/venues/[id]`,
    nftListings: `${marketplace}/nft-listings`,
    nftDetails: `${marketplace}/nft-listings/[id]`,
    creator: `${marketplace}/creator`,
    dex: `${marketplace}/dex`,
    getInTouch: `${marketplace}/#getInTouch`,
    policy: `${marketplace}/policy`,
    search: `${marketplace}/search`,
    staking: `${marketplace}/staking`,
    wert: `${marketplace}/wert`,
    vesting: `${marketplace}/vesting`,
    worldMap: `${marketplace}/world-map`,
  },

  user: {
    home: `${userDashboard}/`,
    avatarGallery: `${userDashboard}/avatar-gallery`,
    avatarDetails: `${userDashboard}/avatar-gallery/[id]`,
    myNFTs: `${userDashboard}/my-inventory`,
    myNFTsDetails: `${userDashboard}/my-inventory/[id]`,
    transactions: `${userDashboard}/transactions`,
    guilds: `${userDashboard}/guilds`,
    createGuild: `${userDashboard}/create-guild`,
    guildDetails: `${userDashboard}/guilds/[id]`,
    editGuild: `${userDashboard}/edit-guild/[id]`,
    events: `${userDashboard}/events`,
    eventDetails: `${userDashboard}/events/[id]`,
    createEvent: `${userDashboard}/create-event`,
    becomeCreator: `${userDashboard}/become-a-creator`,
    editAvatar: `${userDashboard}/edit-avatar/[id]`,
    editEvent: `${userDashboard}/edit-event/[id]`,
    venues: `${userDashboard}/venues`,
    proposalsDetails: `${userDashboard}/proposals/[id]`,
    createProposal: `${userDashboard}/create-proposal`,
    poolsDetails: `${userDashboard}/pools/[id]`,
  },

  aijam: {
    home: `${aijamDashboard}/`,
    avatarGallery: `${aijamDashboard}/avatar-gallery`,
    avatarDetails: `${aijamDashboard}/avatar-gallery/[id]`,
    myNFTs: `${aijamDashboard}/my-inventory`,
    myNFTsDetails: `${aijamDashboard}/my-inventory/[id]`,
    assistantGallery: `${aijamDashboard}/assistant-gallery`,
    assistantDetails: `${aijamDashboard}/assistant-gallery/[id]`,
  },

  admin: {
    home: '/',
    users: '/users',
    usersEdit: '/users/:editId',
    usersCreate: '/users/create',
    venues: '/venues',
    editVenue: '/venues/:editId',
    createVenue: '/venues/create',
    setOwnerVenue: '/venues/:venueId/venueOwner',
    events: '/events',
    eventCreate: '/events/create',
    eventEdit: '/events/:editId',
    eventEditRole: '/events/:editId/eventRoleToUser',
    eventRoles: '/eventRoles',
    eventRolesEdit: '/eventRoles/:editId',
    eventRolesCreate: '/eventRoles/create',
    creators: '/creators',
    creatorEdit: '/creators/:editId',
    becomeCreator: '/becomeCreator',
    instances: '/instances',
    instanceCreate: '/instances/create',
    instanceEdit: '/instances/:editId',
    avatars: '/avatars',
    avatarEdit: '/avatars/:editId',
    customAvatars: '/customAvatars',
    customAvatarCreate: '/customAvatars/create',
    customAvatarEdit: '/customAvatars/:editId',
    assistants: '/assistants',
    assistantEdit: '/assistants/:editId',
    assistantInteractionsEdit: '/assistants/:editId/interactions',
    proposals: '/proposals',
    proposalsEdit: '/proposals/:editId',
    pools: '/pools',
    poolCreate: '/pools/create',
    poolEdit: '/pools/:editId',
    locations: '/locations',
    guilds: '/guilds',
    assignCustomAvatar: '/customAvatars/:editId/assignAvatar',
    tokenPrice: '/tokenPrice',
    tokenPriceCreate: '/tokenPrice/create',
    tokenTransactions: '/tokenTransactions',
  },
};
