export const serviceNameChecker = (serviceName: string, domainFolderName = ''): string => {
  if (domainFolderName) {
    const domainFolderEditFields = {
      eventRoleToUser: 'eventRoleToUser',
      assignAvatar: 'assignAvatar',
      interactions: 'interactions',
      venueOwner: 'venueOwner',
    };

    return domainFolderEditFields[domainFolderName];
  }

  const serviceMapping = {
    events: 'event',
    eventRoles: 'eventRole',
    venues: 'venue',
    users: 'user',
    creators: 'creator',
    becomeCreator: 'becomeCreator',
    instances: 'instance',
    avatars: 'avatar',
    customAvatars: 'customAvatar',
    assistants: 'assistant',
    proposals: 'proposal',
    pools: 'pool',
    locations: 'location',
    guilds: 'guild',
    tokenPrice: 'tokenPrice',
    tokenTransactions: 'tokenTransactions',
  };

  return serviceMapping[serviceName];
};
